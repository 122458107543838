<template>
  <div class="caigou">
      <gxjyheader  title="销售信息" desc="用质量说话  以服务取胜  让我们的产品  点亮您生活的每一刻"></gxjyheader>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item :to="{ path: '/gxjy' }">供需交易</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/caigou' }">供方市场-产品</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-form ref="form" :model="form" style="background: #fff;margin: 20px 0;padding:10px 0" label-width="120px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="产品名称" >
                <el-input placeholder="请输入"   style="width: 218px" v-model="form.productName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="行业">
                <el-select v-model="form.industryId" placeholder="请选择">
                  <el-option
                      v-for="item in getIndustryList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="价格区间（元）" >
                <el-input placeholder="请输入"   style="width: 100px" v-model="form.minPrice"></el-input>
                <el-link :underline="false">---</el-link>
                <el-input placeholder="请输入"   style="width: 100px" v-model="form.maxPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发布时间">
                <el-date-picker
                    class="riqi"
                    v-model="form.date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" size="small" @click="loadlist(true)">筛选</el-button>
            <el-button @click="reset" size="small" >重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="lists">
        <div class="list" v-for="(item,index) in items"  @click="goxq(item)">
          <div class="listimage">
            <div class="biao">供</div>
            <el-image fit="cover" :src="item.images"></el-image>
          </div>
          <div class="h1">
            <div class="card">
              <div class="cardred" v-if="item.isFace==1">￥{{item.price}}元/{{item.unit}}</div>
              <div class="cardred" v-else>面议</div>
            </div>
            <div class="title ellipsis2">{{item.title}}</div>
            <div  class="type text-overflow">
              <span class="product text-overflow">{{item.productName}}</span>   <span>{{item.typeName}}</span>
            </div>
            <div class="need">
              行业：{{item.industryName}}
            </div>
            <div class="need color00">
              {{item.enterpriseName}}
            </div>
            <div class="botline">
              <i class="el-icon-location"></i>
              <span class="address colorC8">{{item.cityName}}</span>
              <el-button class="see" @click.stop.native="zixun(item)" >立即咨询</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[12, 60, 90, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="发送" width="600px" append-to-body :visible.sync="dialogTableVisible">
        <el-form ref="form" :model="shen" label-width="120px" :rules="rules">
          <el-form-item label="咨询详情" prop="detail">
            <el-input rows="5"  style="width: 300px" type="textarea" v-model="shen.detail"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input   v-model="shen.contacts"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input type="number" v-model="shen.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="margin-top: 20px" @click="submitForm()">保存</el-button>
            <el-button @click="dialogTableVisible=!dialogTableVisible">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import gxjyheader from './gxjyheader'
import {
  inventorygetIndustryList,
  inventorygetTypeList,
  inventorylist,
  inventorysubmitconsult
} from "../../request/moudle/demand";
export default {
  name: "zhaobiao",
  data(){
    return {
      form:{},
      page:{
        current:1,
        size:12,
        total:0
      },
      shen:{},
      zxid:'',
      rules: {
        detail: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },
      dialogTableVisible:false,
      items:[],
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    loadconfig(){
      this.$api.demand.inventorygetIndustryList().then((res)=>{
        this.getIndustryList=res.data
      })
      this.$api.demand.inventorygetTypeList().then((res)=>{
        this.getTypeList=res.data
      })
    },
    submitForm(){
      this.shen.inventoryId=this.zxid
      this.$api.demand.inventorysubmitconsult(this.shen).then((res)=>{
        this.dialogTableVisible=false
        this.$message({
          type: "success",
          message: "提交成功!"
        });
      })
    },
    zixun(item){
      this.shen={}
      this.zxid=item.id
      this.dialogTableVisible=true
    },
    goxq(item){
      this.$router.push({
        path:'/kucunxq',
        query:{
          id:item.id,
        }
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      if(this.form.cascader){
        this.form.province=this.form.cascader[0]
        this.form.city=this.form.cascader[1]
        this.form.area=this.form.cascader[2]
      }
      const obj={
        ...this.form
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.demand.inventorylist(obj).then((res)=>{
        this.items=res.data.records
        this.page.total=res.data.total
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    reset(){
      this.form={}
    }
  },
  components:{
    gxjyheader
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-input{
  width: auto;
}
.caigou{
  background: #F5F5F5;
}
.colorC8{
  color: #C8C8C8;
}
.botline{
  padding-top: 15px;
  border-top: 1px dashed;
  margin-top: 15px;
}
.product{
  border-right: 1px solid ;
  margin-right: 8px;
  padding-right: 8px;
}
.color00{
  color: #0078F2;
}
  .content{
    width: 1200px;
    margin: 15px auto;
    .line{
      position: relative;
    }
    .sxx{
      background:#FFFFFF;
      padding:30px  40px;
      margin-top: 25px;
    }
    .fenye{
      text-align: center;
      padding: 30px 0;
      background: #fff;
    }
    .lists{
      overflow: hidden;
      background: #ffffff;
      padding:35px  20px;
      margin-top: 25px;
    }
    .list{
      width: 350px;
      border: 1px solid #D1D1D1;
      border-radius: 8px;
      float: left;
      margin-left: 20px;
      margin-bottom: 20px;
      line-height: 25px;
      cursor: pointer;
      .listimage{
        height: 240px;
        position: relative;
      }
      .biao{
        width: 24px;
        height: 24px;
        border-radius: 1px;
        background: #CCABDA;
        color: #fff;
        text-align: center;
        line-height: 24px;
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 99;
      }
      .title{
        font-size: 16px;
        color: #000000;
        font-weight: bolder;
        margin: 10px 0;
        min-height: 50px;
      }
      .cardred{
        color: red;
        font-size: 16px;
        font-weight: bolder;
        margin-top: 5px;
      }
      .card{
        width: 100%;
        display: inline-block;
      }
      .el-icon-location{
        font-size: 32px;
        color: #D83434;
        vertical-align: middle;
      }
      .address{
        vertical-align: middle;
      }
      .see{
        display: inline-block;
        width: 110px;
        cursor: pointer;
        background: linear-gradient(0deg,#6EC6CA,#6EC6CA);
        color: #fff;
        text-align: center;
        float: right;
        border-radius: 4px;
      }
      .h1{
        margin: 20px 25px;
      }
    }
  }
</style>