<template>
  <div class="header">
    <div class="h1">{{title?title:'供需交易'}}</div>
    <div class="h2">{{desc?desc:'构筑工序桥梁  塑造诚信平台'}}</div>
  </div>
</template>

<script>
export default {
  name: "gxjyheader",
  props:['title','desc']
}
</script>

<style lang="scss" scoped>
.header{
  height: 220px;
  background: url("../../assets/banner/gxjy.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
</style>